<template>
    <div class="appsArea" :class="{[themeName || 'light']: true, 'isTablet': view.isTablet, 'isMobile': view.isMobile}">
        <div class="apps">
            <a @click.stop.prevent="toggleLoginBox" v-if="!isLogin" class="btnUserLogin">登入</a>
            <a @click.stop.prevent="toggleUserBox" v-if="isLogin">
                <div class="userIcon" :style="{'backgroundImage': `url(${currentUser.photoURL || ''})`}"></div>
            </a>
            <a href="#" @click.stop.prevent="showApps = true" ><Icon type="ios-apps"/></a>
            <Icon v-if="globalState.isLogin" type="ios-add-circle" style="margin:0 10px;top:-5px;position: relative" @click="managePush" />
            <Notification :locale="notiLocale" icon="md-notifications" placement="bottom-end" :style="{ fontSize: '20px !important' }">
                <NotificationTab title="通知" name="message" :count="messageList.length">
                    <NotificationItem
                        v-for="(item, index) in messageList"
                        :key="index"
                        :title="item.title"
                        :icon="item.icon"
                        :icon-color="item.iconColor"
                        :time="item.time"
                        :read="item.read"
                    />
                </NotificationTab>
            </Notification>

        </div>

        <div class="userBox" v-show="showUser" :class="{'animation-drop-in': showUser}">
            <div class="userInfo">
                <div class="userIconBig" :style="{'backgroundImage': `url(${currentUser.photoURL || ''})`}"></div>
                <h1>{{ currentUser.displayName }}，你好！</h1>
            </div>
            <div class="userButtons" v-if="isLogin">
                <a class="accountBtn" href="#" @click.prevent.stop="accountManager">帳號管理</a>
                <a class="logoutBtn" href="#" @click.prevent.stop="logout">
                    <Icon type="md-exit"/>
                    登出</a>
            </div>
            <div class="userDarkMode" v-if="isLogin">
                <span class="darkMode">深色模式</span>
                <UseDark v-slot="{ isDark, toggleDark }">
                    <div @click="toggleDark()" class="darkModeArea">
                        <Icon v-show="isDark" type="ios-moon"/>
                        <Icon v-show="!isDark" type="ios-sunny"/>
                    </div>
                </UseDark>
            </div>
        </div>

        <div class="appsBox" v-show="showApps" :class="{'animation-drop-in': showApps}">
            <ul>
                <li v-for="app in mainApps" :key="app.title" @click="location.href = app.url"
                    @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''">
                    <a :href="app.url">
                        <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                    </a>
                </li>
            </ul>
            <ul>
                <li v-for="app in insideApps" :key="app.title" @click="location.href = app.url"
                    @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''">
                    <a :href="app.url">
                        <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                    </a>
                </li>
            </ul>
        </div>
        <loginBox :platformName="platformName" :view="globalState" v-if="showLogin" @maskClick="handleMaskClick"
                  @loginSuccess="handleLoginSuccess"/>
    </div>
</template>

<script>
import {UseDark} from "@vueuse/components";
import loginBox from "./loginBox";
import {globalStateData} from '../globalState';
import {auth} from '@/firebaseConfig';
import {onAuthStateChanged, signOut} from 'firebase/auth';

export default {
    name: 'GSApps',
    components: {
        loginBox,
        UseDark
    },
    setup() {
        const globalState = globalStateData();
        return {globalState};
    },
    props: {
        platformName: String,
        themeName: String,
    },
    data() {
        return {
            appsHover: null,
            appsLink: [
                {
                    title: '碳排盤查',
                    cat: 'main',
                    verify: false,
                    url: 'https://esg.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_esg.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_esg.svg',
                },
                {
                    title: '虛擬電廠',
                    cat: 'main',
                    verify: false,
                    url: 'https://vpp.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_ai.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_ai.svg',
                },
                {
                    title: '能源交易',
                    cat: 'main',
                    verify: false,
                    url: 'https://market.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_vpp.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_vpp.svg',
                },
                {
                    title: '案件流程',
                    cat: 'inside',
                    verify: false,
                    url: 'https://www.greenshepherd.com.tw/afu?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu.svg',
                },
                {
                    title: '維運管理',
                    cat: 'inside',
                    verify: false,
                    url: 'https://om.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_om.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_om.svg',
                },
                {
                    title: '電力交易',
                    cat: 'inside',
                    verify: false,
                    url: 'https://trade.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_trade.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_trade.svg',
                },
                {
                    title: '人資管理',
                    cat: 'inside',
                    verify: false,
                    url: 'https://hr.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '阿福測試區',
                    cat: 'inside',
                    verify: true,
                    url: 'https://afu.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '文件簽核',
                    cat: 'inside',
                    verify: true,
                    url: 'https://sign.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
            ],
            messageList: [],
            message: '',
            showLogin: false,
            showApps: false,
            showUser: false,
            showNoti: false,
            notiLocale:{ loadedAll: '加載完畢', loading: '加載中...', loadMore: '加載更多', clear: '清空' }
        }
    },
    computed: {
        mainApps() {
            return this.appsLink.filter(x => x.cat === 'main' && (!x.verify || this.isLogin));
        },
        insideApps() {
            return this.appsLink.filter(x => x.cat === 'inside' && (!x.verify || this.isLogin));
        },
        otherApps() {
            return this.appsLink.filter(x => x.cat === 'other');
        },
        isLogin() {
            return this.globalState.isLogin;
        },
        currentUser() {
            return this.globalState.currentUser || {};
        },
    },
    mounted() {
        this.initializeAuthListener();
        this.$nextTick(() => {
            document.addEventListener('click', this.handleOutsideClick);
        });
    },
    unmounted() {
        document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
        initializeAuthListener() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.showLogin = false;
                    console.log(user)
                    this.globalState.currentUser = {
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        role: user.role,
                    };
                    this.globalState.isLogin = true;
                    this.eventBus.emit('userLoggedIn', this.globalState.currentUser);
                } else {
                    this.globalState.currentUser = null;
                    this.globalState.isLogin = false;
                    this.eventBus.emit('userLoggedOut');
                }
            });
        },
        async logout() {
            try {
                await signOut(auth);
                this.globalState.handleUserLogout();
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
        accountManager() {
            // 實現帳號管理功能，可能需要導航到 Firebase 提供的帳號管理頁面或自定義頁面
        },
        handleOutsideClick(event) {
            const userBox = this.$el.querySelector('.userBox');
            const appsBox = this.$el.querySelector('.appsBox');
            const notiBox = this.$el.querySelector('.notificationBox');

            if (this.showUser && userBox && !userBox.contains(event.target)) {
                this.showUser = false;
            }

            if (this.showApps && appsBox && !appsBox.contains(event.target)) {
                this.showApps = false;
            }

            if (this.showNoti && notiBox && !notiBox.contains(event.target)) {
                this.showNoti = false;
            }
        },
        toggleLoginBox() {
            this.showLogin = true;
        },
        toggleUserBox() {
            this.showUser = !this.showUser;
            this.showNoti = false;
            this.showApps = false;
        },
        toggleNotificationBox() {
            this.showNoti = !this.showNoti;
            this.showUser = false;
            this.showApps = false;
        },
        toggleAppsBox() {
            this.showApps = !this.showApps;
            this.showNoti = false;
            this.showUser = false;
        },
        handleMaskClick() {
            this.showLogin = false;
        },
        handleLoginSuccess(user) {
            this.showLogin = false;
            this.globalState.currentUser = user;
            this.globalState.isLogin = true;
            this.view.currentUser = user;
            this.eventBus.emit('loginSuccess', user);
        },
        managePush(){
          this.$router.push('/user/memberPage')
        }
    },
}
</script>
<style scoped lang="stylus">
::v-deep(.ivu-notifications)
    top: -5px;
    position:relative

</style>

<style scoped lang="stylus">
.apps
    position: absolute
    font-size: 38px
    line-height: 60px
    cursor: pointer
    top: 14px
    right: 0
    color: #fff
    float: right
    margin: 0

    a
        margin-right: 5px
        float: right
        width: 50px !important
        height: 50px !important
        display: inline-block
        line-height: 50px
        color: #ffffff !important

        &:hover
            color: #f3993a !important

        .userIcon
            margin-top: 4px
            background-color #fff
            border: solid 4px #eee
            border-radius: 50%
            background-size 100%
            width: 50px !important
            height: 50px !important

            &:hover
                border: solid 3px #f3993a

    .btnUserLogin
        float: right
        font-size: 14px
        font-weight: 600
        padding: 10px 15px
        width: 75px !important
        height: 32px !important
        line-height: 10px
        background: #f08200
        border: solid 2px #f08200
        color: #fff !important
        margin: 10px 15px 10px 0
        display: inline-block
        text-align: center
        border-radius: 5px

        &:hover
            background: #ffffff
            color: #f08200

.appsArea
    position: relative

.userBox
    user-select: none
    position: absolute
    top: 72px
    right: 12px
    height: 360px
    width: 325px
    padding 0
    background: #99999966
    border: solid 12px #aaaaaa05
    border-radius: 6px
    z-index: 9999999
    overflow-y: auto
    overflow-x: hidden
    box-shadow: 0 0 10px #00000033

    &::-webkit-scrollbar
        width: 2px
        height: 8px
        background-color: transparent

    &::-webkit-scrollbar-thumb
        background: #ffffff33
        &:hover
            background-color: var(--focus-color)

    &>div
        border-radius: 8px
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        margin: 5px 15px 5px 5px
        background: var(--apps-bg-color)
        color: #bbb

    .userInfo
        padding: 25px 15px
        h1
            margin-top: 25px
        .userIconBig
            border-radius: 50%
            border: solid 5px #fff
            background: #dedede
            background-size: 100%
            width: 100px
            height: 100px

    .userButtons
        height: 42px
        line-height: 40px
        border: solid 1px #aaaaaa05
        border-radius: 8px
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        margin: 5px 15px 5px 5px
        background: var(--apps-bg-color)
        color: #fff
        padding: 0 20px

        .accountBtn,
        .logoutBtn
            border-radius: 8px 0 0 8px
            font-size: 16px
            margin-right: 2px
            overflow: hidden
            color: #ffffff

        .logoutBtn
            border-radius: 0 8px 8px 0
            text-align: right

    .userDarkMode
        border: solid 1px #aaaaaa05
        border-radius: 8px
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        margin: 5px 15px 5px 5px
        background: var(--apps-bg-color)
        color: #fff
        padding: 0 20px

        .darkModeArea
            cursor: pointer
            float: right
            margin: 10px 10px
            border: 1px solid #ffffff
            background-color: #dddddd
            width: 42px
            height: 23px
            border-radius: 30px

            .ivu-icon
                float: left
                font-size: 16px
                margin: 2px 3px
                background: #fff
                border-radius: 30px
                color: #000

                &.ivu-icon-ios-moon
                    color: #ffffff
                    background: #000000
                    margin-left: 19px
                    margin-right: 1px

.appsBox
    user-select: none
    position: absolute
    top: 72px
    right: 52px
    height: 320px
    width: 325px
    background: #99999966
    border: solid 12px #aaaaaa05
    border-radius: 6px
    z-index: 9999999
    overflow-y: auto
    overflow-x: hidden

    &::-webkit-scrollbar
        width: 2px
        height: 8px
        background-color: transparent

    &::-webkit-scrollbar-thumb
        background: #ffffff33
        &:hover
            background-color: var(--focus-color)

    ul
        width: 300px
        background: var(--apps-bg-color)
        border-radius: 8px
        display: flex
        flex-wrap: wrap
        margin-bottom: 12px
        margin-right: 5px
        color: #ffffff

    li
        padding: 5px
        list-style-type: none
        cursor: pointer
        width: 100px
        height: 90px
        overflow: hidden

        img
            height: 50px
            border: none
            max-width: 100%

        &:hover a
            color: #96C800

        a
            color: #bbbbbb
            font-size: 16px
            line-height: 25px
            display: block

.animation-drop-in
    animation: ivuTransitionDropIn 472.6ms ease-out
    transform-origin: center top
    position: absolute
    will-change: top, left

.light
    .apps
        a, span
            color: var(--link-color) !important
            .userIcon
                background-color: #dedede
                border: solid 4px var(#ffffff) !important
            &:hover
                color: var(--link-focus-color) !important
                .userIcon
                    border: solid 4px var(--link-focus-color) !important
            &.btnUserLogin
                color: #fff !important
                &:hover
                    color: #f08200 !important

.apps
    margin 0 !important
    top: 12px
    right: 0px
    font-size 28px
    a.btnNotification
        color: #ffffff !important
        font-size 42px
        width 42px !important
        margin: 5px
        float: right
        height: 42px !important
        display: inline-block
        line-height: 32px
        &:hover
            color: #f3993a !important
    a.btnUserLogin
        margin 0px 10px !important

    .userIcon
        margin-top 0px !important

.userBox
    top: 72px
    right: 10px
.appsBox
    top: 52px
    right: 25px
.notificationBox
    top: 52px
    right: 42px
</style>
