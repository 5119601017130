import { defineStore } from 'pinia';
import { useDark } from "@vueuse/core";
import mitt from "mitt";
import { auth } from '@/firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';

let originalSetInterval = window.setInterval;
let originalSetTimeout = window.setTimeout;
let intervalTimerList = [];
let timeOutTimerList = [];

export const globalStateData = defineStore('globalStateData', {
    state: () => {
        return {
            isDark: useDark(),
            isMobile: true,
            isTablet: false,
            pageName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            drawerInfo: {
                show: false,
                name: '',
                title: '',
                width: 600,
                parameter: {},
            },
            isChecked: false,
            windowWidth: window.innerWidth,
            routeFrom: null,
            routeTo: null,
            isLogin: false,
            pendingRoute: null,
        }
    },
    getters: {
    },
    actions: {
        initializeAuthListener() {
            return new Promise((resolve) => {
                onAuthStateChanged(auth, (user) => {
                    let currentUser = null;
                    if (user) {
                        currentUser = {
                            uid: user.uid,
                            email: user.email,
                            displayName: user.displayName,
                            photoURL: user.photoURL,
                            role: user.role,
                        };
                        this.isLogin = true;
                        this.eventBus.emit('userLoggedIn', currentUser);
                    } else {
                        this.isLogin = false;
                        this.eventBus.emit('userLoggedOut');
                    }
                    this.isChecked = true;

                    this.eventBus.emit('userInit', currentUser);
                    resolve();
                });
            });
        },
        async handleUserLogout() {
            try {
                await signOut(auth);
                this.eventBus.emit('userLoggedOut');
            } catch (error) {
                console.error("登出失敗", error);
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.drawerInfo.show = true
            this.drawerInfo.title = drawerTitle
            this.drawerInfo.parameter = drawerParameter
            setTimeout(() => {
                this.drawerInfo.name = drawerName
            }, 330)
        },
        setPageInterval(callback, ms, keep = false) {
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        setPageTimeout(callback, ms, keep = false){
            keep = keep || false
            let i = originalSetTimeout(callback, ms);
            if (!keep) timeOutTimerList.push(i);
            return i
        },
        clearPageTimeout(){
            timeOutTimerList.forEach(i=>{clearTimeout(i);});
            timeOutTimerList=[];
        },
        beforePageChange(to, from) {
            this.clearPageInterval();
            this.clearPageTimeout();
            this.routeFrom = from;
            this.routeTo = to;
            if (to.meta && to.meta.requiresAuth) {
                if (!this.isChecked) {
                    // Auth 狀態尚未確定，先記錄目標路由
                    this.pendingRoute = to.fullPath;
                    return { name: 'general_isLoading' };
                } else if (!this.isLogin) {
                    return { name: 'general_needLogin' };
                }
            }
        },
        checkPendingRoute() {
            if (this.pendingRoute) {
                const route = this.pendingRoute;
                this.pendingRoute = null;
                return route;
            }
            return null;
        },
        afterPageChange() {
            const from = this.routeFrom;
            const to = this.routeTo;
            let path = location.pathname;
            let pageName = path.split('/').join('_').substring(1);
            if (pageName.length === 1) pageName = 'components_' + pageName;
            this.pageName = to?.meta?.pageName ? to?.meta.pageName : pageName;
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0];
            }, 30);
            this.eventBus.emit('afterPageChange', {path, from, to, pageName});
        },
    },
})

export default {
    install: (app) => {
        const bus = mitt()
        const gs = globalStateData()
        const router = app.config.globalProperties.$router
        gs.eventBus = bus
        if (router){
            router.beforeEach(async (to, from) => {
                if (!gs.isChecked) {
                    // 等待 auth 初始化完成
                    await gs.initializeAuthListener();
                }
                return gs.beforePageChange(to, from);
            });

            router.afterEach((to, from, failure) => {
                if (!failure) {
                    // 檢查是否有待處理的路由
                    const pendingRoute = gs.checkPendingRoute();
                    if (pendingRoute) {
                        router.push(pendingRoute);
                    }else {
                        gs.afterPageChange();
                    }
                }
            });
        }
        const view = app.config.globalProperties.view
        if (view){
            view.eventBus = bus
            view.globalState = gs
            const vsProxy = new Proxy(view, {
                get(target, key) {
                    if (key in target) return Reflect.get(target, key);
                    return Reflect.get(gs, key);
                },
                set(target, key, value) {
                    if (key in target) {
                        Reflect.set(target, key, value);
                        return true;
                    }
                    Reflect.set(gs, key, value);
                    return true;
                }
            });
            app.config.globalProperties.vs = vsProxy;
            app.config.globalProperties.view = vsProxy;
        }
        app.config.globalProperties.eventBus = bus;
        app.config.globalProperties.gs = gs;
        app.config.globalProperties.globalState = gs;
        app.config.globalProperties.auth = auth;
    }
}